import React, { Component } from 'react';
import {
    getValueForVariable,
    getDrill,
    getTransportationServicesDrilldown,
    getRecreationDrillDown,
    getEnvironmentServicesDrilldown,
    getProtectionServicesDrilldown
} from "./Utils";
import { StatsBody } from "./StatsBody";


export class CapitalAssetsPage extends Component {

    getDataTableFields() {
        return {
            fields: [
                getDrill(
                    getTransportationServicesDrilldown("C01.11", "51A", this.props),
                    this.props
                ),

                getDrill(
                    getRecreationDrillDown("C01.11", "51A", this.props),
                    this.props
                ),

                getDrill(
                    getEnvironmentServicesDrilldown("C01.11", "51A", this.props),
                    this.props
                ),

                getDrill(
                    {
                        custom: {
                            id: { "value": "See Calculation", "popover": "slc.51A.L1899.C01.11 + slc.51A.L1299.C01.11 + slc.51A.L0299.C01.11 + slc.51A.L0499.C01.11 + slc.51A.L1499.C01.11 + slc.51A.L1099.C01.11" },
                            desc: "Gen. Gov./Protection/Env./Social Services",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.51A.L1899.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.51A.L1299.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.51A.L1499.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.51A.L1099.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.51A.L0499.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.51A.L0299.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                                )
                            }
                        },
                        desc: "Gen. Gov., Protection, Social Services",
                        subdrills: [
                            { desc: "Planning and Development", linenum: "1899", schedule: "51A", col: "C01.11" },
                            {
                                desc: "Social and Family Services", linenum: "1299", schedule: "51A", col: "C01.11",
                                subdrills: [
                                    { desc: "General Assistance", linenum: "1210", schedule: "51A", col: "C01.11" },
                                    { desc: "Assistance to aged persons", linenum: "1220", schedule: "51A", col: "C01.11" },
                                    { desc: "Child Care", linenum: "1230", schedule: "51A", col: "C01.11" },
                                    { desc: "Other", linenum: "1298", schedule: "51A", col: "C01.11" },
                                    { desc: "Total Social and Family", linenum: "1299", schedule: "51A", col: "C01.11" }
                                ]
                            },
                            {
                                desc: "Social Housing", linenum: "1499", schedule: "51A", col: "C01.11",
                                subdrills: [
                                    { desc: "Public Housing", linenum: "1410", schedule: "51A", col: "C01.11" },
                                    { desc: "Non-profit/Cooperative Housing", linenum: "1420", schedule: "51A", col: "C01.11" },
                                    { desc: "Rent Supplement Programs", linenum: "1430", schedule: "51A", col: "C01.11" },
                                    { desc: "Other 1", linenum: "1497", schedule: "51A", col: "C01.11" },
                                    { desc: "Other 2", linenum: "1498", schedule: "51A", col: "C01.11" },
                                    { desc: "Total Public Housing", linenum: "1499", schedule: "51A", col: "C01.11" }
                                ]
                            },
                            {
                                desc: "Health Services", linenum: "1099", schedule: "51A", col: "C01.11",
                                subdrills: [
                                    { desc: "Public Health Services", linenum: "1010", schedule: "51A", col: "C01.11" },
                                    { desc: "Hospitals", linenum: "1020", schedule: "51A", col: "C01.11" },
                                    { desc: "Ambulance Services", linenum: "1030", schedule: "51A", col: "C01.11" },
                                    { desc: "Ambulance Dispatch", linenum: "1035", schedule: "51A", col: "C01.11" },
                                    { desc: "Cemeteries", linenum: "1040", schedule: "51A", col: "C01.11" },
                                    { desc: "Other", linenum: "1098", schedule: "51A", col: "C01.11" },
                                    { desc: "Total Health Services", linenum: "1099", schedule: "51A", col: "C01.11" }
                                ]
                            },
                            getProtectionServicesDrilldown("C01.11", "51A", this.props),
                            { desc: "General Government", linenum: "0299", schedule: "51A", col: "C01.11" },
                            {
                                custom: {
                                    id: { "value": "See Calculation", "popover": "slc.51A.L1899.C01.11 + slc.51A.L1299.C01.11 + slc.51A.L0299.C01.11 + slc.51A.L0499.C01.11 + slc.51A.L1499.C01.11 + slc.51A.L1099.C01.11" },
                                    desc: "Total Gen. Gov./Protection/Env./Social Services",
                                    calc: (d, i, total, year) => {
                                        return (
                                            getValueForVariable(d, 'slc.51A.L1899.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                            getValueForVariable(d, 'slc.51A.L1299.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                            getValueForVariable(d, 'slc.51A.L1499.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                            getValueForVariable(d, 'slc.51A.L1099.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                            getValueForVariable(d, 'slc.51A.L0499.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                            getValueForVariable(d, 'slc.51A.L0299.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                                        )
                                    }
                                },
                            }
                        ]
                    },
                    this.props
                ),

                getDrill(
                    { desc: "Other", linenum: "1910", schedule: "51A", col: "C01.11" },
                    this.props
                ),

                getDrill(
                    { desc: "Total", linenum: "9910", schedule: "51A", col: "C01.11" },
                    this.props
                ),
            ],
            totalID: 'slc.51A.L9910.C01.11',
            header: "Tangible Capital Assets"
        }
    }

    render() {

        if (this.props.cities.length === 0) {
            return <div></div>;
        }

        return (
            <div>
                <StatsBody title="Net Book Value of Tangible Capital Assets"
                    dataTableFields={this.getDataTableFields()}
                    agg={this.props.agg}
                    type={this.props.type}
                    drillto={this.props.drillto}
                    cities={this.props.cities}
                    selectedCities={this.props.selectedCities}>
                </StatsBody>
            </div>
        )
    }
}

