import React, { Component } from 'react';
import { StatsBody } from './StatsBody';
import {
    getValueForVariable,
    getProtectionServicesDrilldown, getDrill,
    getTransportationServicesDrilldown,
    getEnvironmentServicesDrilldown,
    getRecreationDrillDown
} from "./Utils";



export class RevenuePage extends Component {


    getS12Subdrills(column) {
        return [
            getProtectionServicesDrilldown(column, "12X", this.props),
            getTransportationServicesDrilldown(column, "12X", this.props),
            getEnvironmentServicesDrilldown(column, "12X", this.props),
            {
                desc: "Health Services", linenum: "1099", schedule: "12X", col: column,
                subdrills: [
                    { desc: "Public Health Services", linenum: "1010", schedule: "12X", col: column },
                    { desc: "Hospitals", linenum: "1020", schedule: "12X", col: column },
                    { desc: "Ambulance Services", linenum: "1030", schedule: "12X", col: column },
                    { desc: "Ambulance Dispatch", linenum: "1035", schedule: "12X", col: column },
                    { desc: "Cemeteries", linenum: "1040", schedule: "12X", col: column },
                    { desc: "Other", linenum: "1098", schedule: "12X", col: column },
                    { desc: "Total Health Services", linenum: "1099", schedule: "12X", col: column }
                ]
            },
            {
                desc: "Social and Family", linenum: "1299", schedule: "12X", col: column,
                subdrills: [
                    { desc: "General Assistance", linenum: "1210", schedule: "12X", col: column },
                    { desc: "Assistance to aged persons", linenum: "1220", schedule: "12X", col: column },
                    { desc: "Child Care", linenum: "1230", schedule: "12X", col: column },
                    { desc: "Other", linenum: "1298", schedule: "12X", col: column },
                    { desc: "Total Social and Family", linenum: "1299", schedule: "12X", col: column }
                ]

            },
            {
                desc: "Social Housing", linenum: "1499", schedule: "12X", col: column,
                subdrills: [
                    { desc: "Public Housing", linenum: "1410", schedule: "12X", col: column },
                    { desc: "Non-profit/Cooperative Housing", linenum: "1420", schedule: "12X", col: column },
                    { desc: "Rent Supplement Programs", linenum: "1430", schedule: "12X", col: column },
                    { desc: "Other 1", linenum: "1497", schedule: "12X", col: column },
                    { desc: "Other 2", linenum: "1498", schedule: "12X", col: column },
                    { desc: "Total Public Housing", linenum: "1499", schedule: "12X", col: column }
                ]
            },
            getRecreationDrillDown(column, "12X", this.ptops),
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc.12X.L1899." + column + " + slc.12X.L1910." + column + "" },
                    desc: "Planning, Development and Other",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.12X.L1899.' + column, i, total, year, this.props.selectedCities, this.props.agg) +
                            getValueForVariable(d, 'slc.12X.L1910.' + column, i, total, year, this.props.selectedCities, this.props.agg) +
                            getValueForVariable(d, 'slc.12X.L0299.' + column, i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    }
                },
                desc: "Planning, Development and Other",
                subdrills: [
                    { desc: "General Government", linenum: "0299", schedule: "12X", col: column },
                    { desc: "Planning and Zoning", linenum: "1810", schedule: "12X", col: column },
                    { desc: "Commercial and Industrial", linenum: "1820", schedule: "12X", col: column },
                    { desc: "Residential Development", linenum: "1830", schedule: "12X", col: column },
                    { desc: "Agricultural and Reforestation", linenum: "1840", schedule: "12X", col: column },
                    { desc: "Tile Draining and Shoreline Assistance", linenum: "1850", schedule: "12X", col: column },
                    { desc: "Planning and Development - Other", linenum: "1898", schedule: "12X", col: column },
                    { desc: "General - Other", linenum: "1910", schedule: "12X", col: column },
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc.12X.L1899." + column + " + slc.12X.L1910." + column + "" },
                            desc: "Total Planning, Development and Other",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.12X.L1899.' + column, i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.12X.L1910.' + column, i, total, year, this.props.selectedCities, this.props.agg) +
                                    getValueForVariable(d, 'slc.12X.L0299.' + column, i, total, year, this.props.selectedCities, this.props.agg)
                                )
                            }
                        }
                    }
                ]
            },
            { desc: "Total", linenum: "9910", schedule: "12X", col: column },
        ]
    }

    getDataTableFields() {
        return {
            fields: [
                getDrill({
                    desc: "Property Taxes & Payments-In-Lieu of Taxes", linenum: "9940", schedule: "10X", col: "C01.01",
                    subdrills: [
                        {
                            desc: "Property Taxes", linenum: "0299", schedule: "10X", col: "C01.01",
                            subdrills: [
                                { desc: "Residential Property Taxes", linenum: "9110", schedule: "26A", col: "C01.03" },
                                { desc: "Commercial Property Taxes", linenum: "9120", schedule: "26A", col: "C01.03" },
                                { desc: "Industrial Property Taxes", linenum: "9130", schedule: "26A", col: "C01.03" },
                                {
                                    custom: {
                                        id: { value: "See Calculation", popover: "slc.26A.L0705.C01.03 + slc.26A.L0710.C01.03 + slc.26A.L0810.C01.03 + slc.26A.L9160.C01.03 + slc.26A.L9170.C01.03 + slc.26A.L9190.C01.03 + slc.26A.L9192.C01.03" },
                                        desc: "Other",
                                        calc: (d, i, total, year) => {
                                            return (
                                                getValueForVariable(d, 'slc.26A.L0705.C01.03', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.26A.L0710.C01.03', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.26A.L0810.C01.03', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.26A.L9160.C01.03', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.26A.L9170.C01.03', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.26A.L9190.C01.03', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.26A.L9192.C01.03', i, total, year, this.props.selectedCities, this.props.agg)
                                            )
                                        }
                                    }
                                },
                                {
                                    custom: {
                                        id: { value: "See Calculation", popover: "slc.10X.L0299.C01.01 - slc.26A.L9199.C01.03" },
                                        desc: "Education Tax and Other Adjustments",
                                        calc: (d, i, total, year) => {
                                            return (
                                                getValueForVariable(d, 'slc.10X.L0299.C01.01', i, total, year, this.props.selectedCities, this.props.agg) -
                                                getValueForVariable(d, 'slc.26A.L9199.C01.03', i, total, year, this.props.selectedCities, this.props.agg)
                                            )
                                        }
                                    }
                                },
                                { desc: "Property Taxes Total", linenum: "0299", schedule: "10X" }
                            ]
                        },
                        { desc: "Payments-In-Lieu of Taxes", linenum: "0499", schedule: "10X", col: "C01.01" },
                        { desc: "Total Property Taxes & Payments-In-Lieu of Taxes", linenum: "9940", schedule: "10X", col: "C01.01" }
                    ]
                }, this.props)
                ,
                getDrill({
                    custom: {
                        id: { value: "See Calculation", popover: "slc.10X.L0899.C01.01 + slc.10X.L0699.C01.01" }, desc: "Grants from Other Levels of Government",
                        calc: (d, i, total, year) => {
                            return (
                                getValueForVariable(d, 'slc.10X.L0899.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                getValueForVariable(d, 'slc.10X.L0699.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                            )
                        }
                    },
                    desc: "Grants from Other Levels of Government",
                    subdrills: [
                        {
                            desc: "Conditional Grants", linenum: "0899", schedule: "10X", col: "C01.01",
                            subdrills: [
                                {
                                    desc: "Ontario Conditional Grants", linenum: "9910", schedule: "12X", col: "C01.01",
                                    subdrills: this.getS12Subdrills("C01.01")
                                },
                                {
                                    desc: "Ontario Grants for Tangible Capital Assets", linenum: "9910", schedule: "12X", col: "C01.05",
                                    subdrills: this.getS12Subdrills("C01.05")
                                },
                                {
                                    desc: "Canada Conditional Grants", linenum: "9910", schedule: "12X", col: "C01.02",
                                    subdrills: this.getS12Subdrills("C01.02")
                                },
                                {
                                    desc: "Canada Grants for Tangible Capital Assets", linenum: "9910", schedule: "12X", col: "C01.06",
                                    subdrills: this.getS12Subdrills("C01.06")
                                },
                                { desc: "Defered Revenue (Provinical Gas Tax)", linenum: "0830", schedule: "10X", col: "C01.01" },
                                { desc: "Defered Revenue (Canada Gas Tax)", linenum: "0831", schedule: "10X", col: "C01.01" },
                                { desc: "Total", linenum: "0899", schedule: "10X" }
                            ]
                        },
                        {
                            custom: {
                                id: { value: "See Calculation", popover: "slc.10X.L0620.C01.01 + slc.10X.L0695.C01.01 + slc.10X.L0696.C01.01 + slc.10X.L0697.C01.01 + slc.10X.L0698.C01.01" },
                                desc: "Other Grants",
                                calc: (d, i, total, year) => {
                                    return (
                                        getValueForVariable(d, 'slc.10X.L0620.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0695.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0696.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0697.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0698.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                    )
                                },
                            },
                            desc: "Other",
                            subdrills: [
                                { desc: "Ontario Municipal Partnership Fund (OMPF)", linenum: "0620", schedule: "10X", col: "C01.01" },
                                { desc: "Other 1", linenum: "0695", schedule: "10X", col: "C01.01" },
                                { desc: "Other 2", linenum: "0696", schedule: "10X", col: "C01.01" },
                                { desc: "Other 3", linenum: "0697", schedule: "10X", col: "C01.01" },
                                { desc: "Other 4", linenum: "0698", schedule: "10X", col: "C01.01" },
                                {
                                    custom: {
                                        id: { value: "See Calculation", popover: "slc.10X.L0899.C01.01 + slc.10X.L0699.C01.01" },
                                        desc: "Total Other Grants from Other Levels of Government",
                                        calc: (d, i, total, year) => {
                                            return (
                                                getValueForVariable(d, 'slc.10X.L0899.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.10X.L0699.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                            )
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            custom: {
                                id: { value: "See Calculation", popover: "slc.10X.L0899.C01.01 + slc.10X.L0699.C01.01" },
                                desc: "Total Grants from Other Levels of Government",
                                calc: (d, i, total, year) => {
                                    return (
                                        getValueForVariable(d, 'slc.10X.L0899.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0699.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                    )
                                }
                            }
                        }
                    ]
                }, this.props),
                getDrill(
                    {
                        desc: "User Fees and Service Charges", linenum: "1299", schedule: "10X", col: "C01.01",
                        subdrills: this.getS12Subdrills("C01.04")
                    }
                    , this.props),
                getDrill(
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc.10X.L9910.C01.01 - ( slc.10X.L9940.C01.01 + slc.10X.L0899.C01.01 + slc.10X.L0699.C01.01 + slc.10X.L1299.C01.01 )" }, desc: "Other",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.10X.L9910.C01.01', i, total, year, this.props.selectedCities, this.props.agg) - (
                                        getValueForVariable(d, 'slc.10X.L9940.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0899.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L0699.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.10X.L1299.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                    )
                                )
                            }
                        },
                        desc: "Other Revenues",
                        subdrills: [
                            {
                                desc: "Licenses, Permits, Rents, etc.", linenum: "1499", schedule: "10X", col: "C01.01",
                                subdrills: [
                                    { desc: "Trailer Revenue and Permits", linenum: "1410", schedule: "10X", col: "C01.01" },
                                    { desc: "Licenses and Permits", linenum: "1420", schedule: "10X", col: "C01.01" },
                                    { desc: "Rents, Concessions, and Franchises", linenum: "1430", schedule: "10X", col: "C01.01" },
                                    { desc: "Royalties", linenum: "1431", schedule: "10X", col: "C01.01" },
                                    { desc: "Green Energy", linenum: "1432", schedule: "10X", col: "C01.01" },
                                    { desc: "Other", linenum: "1498", schedule: "10X", col: "C01.01" },
                                    { desc: "Total Licenses, Permits, Rents, etc.", linenum: "1499", schedule: "10X", col: "C01.01" },
                                ]
                            },
                            {
                                desc: "Fine and Penalties", linenum: "1699", schedule: "10X", col: "C01.01",
                                subdrills: [
                                    { desc: "Provincial Offences Act", linenum: "1605", schedule: "10X", col: "C01.01" },
                                    { desc: "Other Fines", linenum: "1610", schedule: "10X", col: "C01.01" },
                                    { desc: "Penalties and Interest on Taxes", linenum: "1620", schedule: "10X", col: "C01.01" },
                                    { desc: "Other", linenum: "1698", schedule: "10X", col: "C01.01" },
                                    { desc: "Total Fines and Penalties", linenum: "1699", schedule: "10X", col: "C01.01" },
                                ]
                            },
                            { desc: "Municipal Land Transfer Tax", linenum: "1880", schedule: "10X", col: "C01.01" },
                            { desc: "Revenues from Other Municipalities for TCA", linenum: "1098", schedule: "10X", col: "C01.01" },
                            { desc: "Revenues from Other Municipalities", linenum: "1099", schedule: "10X", col: "C01.01" },
                            { desc: "Increase in Gov. Business Enterprise Equity", linenum: "1905", schedule: "10X", col: "C01.01" },
                            {
                                desc: "Other Revenue", linenum: "1899", schedule: "10X", col: "C01.01",
                                subdrills: [
                                    {
                                        custom: {
                                            id: { value: "See Calculation", popover: "slc.10X.L1805.C01.01 + slc.10X.L1806.C01.01" }, desc: "Interest and Investments",
                                            calc: (d, i, total, year) => {
                                                return (
                                                    getValueForVariable(d, 'slc.10X.L1805.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1806.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                )
                                            }
                                        },
                                        desc: "Interest and Investments",
                                        subdrills: [
                                            { desc: "Investment Income", linenum: "1805", schedule: "10X", col: "C01.01" },
                                            { desc: "Interest earned on reserves", linenum: "1806", schedule: "10X", col: "C01.01" },
                                            {
                                                custom: {
                                                    id: { value: "See Calculation", popover: "" }, desc: "Total Interest and Investments",
                                                    calc: (d, i, total, year) => {
                                                        return (
                                                            getValueForVariable(d, 'slc.10X.L1805.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1806.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                        )
                                                    }
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        custom: {
                                            id: { value: "See Calculation", popover: "slc.10X.L1811.C01.01 + slc.10X.L1812.C01.01 + slc.10X.L1813.C01.01 + slc.10X.L1814.C01.01" },
                                            desc: "Deferred Revenue and Capital Assets",
                                            calc: (d, i, total, year) => {
                                                return (
                                                    getValueForVariable(d, 'slc.10X.L1811.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1812.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1813.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1814.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                )
                                            }
                                        },
                                        desc: "Deferred Revenue and Capital Assets",
                                        subdrills: [
                                            { desc: "Gain/Loss on Sale of Land & Capital Assets", linenum: "1811", schedule: "10X", col: "C01.01" },
                                            { desc: "Deferred Revenue Earned - Development Charges", linenum: "1812", schedule: "10X", col: "C01.01" },
                                            { desc: "Deferred Revenue Earned - Recreational Land", linenum: "1813", schedule: "10X", col: "C01.01" },
                                            { desc: "Other Deferred Revenue", linenum: "1814", schedule: "10X", col: "C01.01" },
                                            {
                                                custom: {
                                                    id: { value: "See Calculation", popover: "slc.10X.L1811.C01.01 + slc.10X.L1812.C01.01 + slc.10X.L1813.C01.01 + slc.10X.L1814.C01.01" },
                                                    desc: "Total Deferred Revenue and Capital Assets",
                                                    calc: (d, i, total, year) => {
                                                        return (
                                                            getValueForVariable(d, 'slc.10X.L1811.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1812.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1813.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1814.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                        )
                                                    }
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        custom: {
                                            id: { value: "See Calculation", popover: "slc.10X.L1830.C01.01 + slc.10X.L1831.C01.01" }, desc: "Donations",
                                            calc: (d, i, total, year) => {
                                                return (
                                                    getValueForVariable(d, 'slc.10X.L1830.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1831.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                )
                                            }
                                        },
                                        subdrills: [
                                            { desc: "Donations", linenum: "1830", schedule: "10X", col: "C01.01" },
                                            { desc: "Donated Tangible Capital Assets", linenum: "1831", schedule: "10X", col: "C01.01" },
                                            {
                                                custom: {
                                                    id: { value: "See Calculation", popover: "slc.10X.L1830.C01.01 + slc.10X.L1831.C01.01" }, desc: "Total Donations",
                                                    calc: (d, i, total, year) => {
                                                        return (
                                                            getValueForVariable(d, 'slc.10X.L1830.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1831.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                        )
                                                    }
                                                }
                                            }
                                        ],
                                        desc: "Donations"
                                    },
                                    { desc: "Sale of Publications, Equipment, etc.", linenum: "1850", schedule: "10X", col: "C01.01" },
                                    { desc: "Contributions from non-consolidated entities", linenum: "1850", schedule: "10X", col: "C01.01" },
                                    { desc: "Other Revenues from Gov. Bus. Enterprises", linenum: "1865", schedule: "10X", col: "C01.01" },
                                    { desc: "Gaming and Casino Revenues", linenum: "1870", schedule: "10X", col: "C01.01" },
                                    {
                                        custom: {
                                            id: { value: "See Calculation", popover: "Sum of slc.10X.L189X.C01.01 where X is 0 through 8" }, desc: "Other",
                                            calc: (d, i, total, year) => {
                                                return (
                                                    getValueForVariable(d, 'slc.10X.L1890.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1891.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1892.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1893.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1894.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1895.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1896.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1897.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                    getValueForVariable(d, 'slc.10X.L1898.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                )
                                            }
                                        },
                                        desc: "Other",
                                        subdrills: [
                                            {
                                                custom: {
                                                    id: { value: "See Calculation", popover: "Sum of slc.10X.L189X.C01.01 where X is 0 through 4" }, desc: "Group 1",
                                                    calc: (d, i, total, year) => {
                                                        return (
                                                            getValueForVariable(d, 'slc.10X.L1890.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1891.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1892.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1893.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1894.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                        )
                                                    }
                                                },
                                                desc: "Other Group 1",
                                                subdrills: [
                                                    { desc: "Other Item 1", linenum: "1890", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 2", linenum: "1891", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 3", linenum: "1892", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 4", linenum: "1893", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 5", linenum: "1894", schedule: "10X", col: "C01.01" },
                                                    {
                                                        custom: {
                                                            id: { value: "See Calculation", popover: "Sum of slc.10X.L189X.C01.01 where X is 5 through 8" }, desc: "Total - Other Group 1",
                                                            calc: (d, i, total, year) => {
                                                                return (
                                                                    getValueForVariable(d, 'slc.10X.L1890.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1891.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1892.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1893.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1894.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                                )
                                                            }
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                custom: {
                                                    id: { value: "See Calculation", popover: "Sum of slc.10X.L189X.C01.01 where X is 5 through 8" }, desc: "Group 2",
                                                    calc: (d, i, total, year) => {
                                                        return (
                                                            getValueForVariable(d, 'slc.10X.L1895.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1896.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1897.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1898.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                        )
                                                    }
                                                },
                                                desc: "Other Group 2",
                                                subdrills: [
                                                    { desc: "Other Item 1", linenum: "1895", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 2", linenum: "1896", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 3", linenum: "1897", schedule: "10X", col: "C01.01" },
                                                    { desc: "Other Item 4", linenum: "1898", schedule: "10X", col: "C01.01" },
                                                    {
                                                        custom: {
                                                            id: { value: "See Calculation", popover: "Sum of slc.10X.L189X.C01.01 where X is 5 through 8" }, desc: "Total - Other Group 2",
                                                            calc: (d, i, total, year) => {
                                                                return (
                                                                    getValueForVariable(d, 'slc.10X.L1895.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1896.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1897.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                                    getValueForVariable(d, 'slc.10X.L1898.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                                )
                                                            }
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                custom: {
                                                    id: { value: "See Calculation", popover: "Sum of slc.10X.L189X.C01.01 where X is 0 through 8" }, desc: "Total Other",
                                                    calc: (d, i, total, year) => {
                                                        return (
                                                            getValueForVariable(d, 'slc.10X.L1890.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1891.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1892.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1893.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1894.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1895.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1896.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1897.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                            getValueForVariable(d, 'slc.10X.L1898.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                                        )
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    { desc: "Total Other Revenue", linenum: "1899", schedule: "10X", col: "C01.01" }
                                ]
                            },
                            {
                                custom: {
                                    id: { value: "See Calculation", popover: "slc.10X.L9910.C01.01 - ( slc.10X.L9940.C01.01 + slc.10X.L0899.C01.01 + slc.10X.L0699.C01.01 + slc.10X.L1299.C01.01 )" }, desc: "Other",
                                    calc: (d, i, total, year) => {
                                        return (
                                            getValueForVariable(d, 'slc.10X.L9910.C01.01', i, total, year, this.props.selectedCities, this.props.agg) - (
                                                getValueForVariable(d, 'slc.10X.L9940.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.10X.L0899.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.10X.L0699.C01.01', i, total, year, this.props.selectedCities, this.props.agg) +
                                                getValueForVariable(d, 'slc.10X.L1299.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                                            )
                                        )
                                    }
                                }
                            }
                        ]
                    }, this.props)
                , {
                    id: "slc.10X.L9910.C01.01", desc: "Total",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.10X.L9910.C01.01', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    }
                }
            ],
            totalID: 'slc.10X.L9910.C01.01',
            header: 'All Revenues'
        }
    }

    render() {

        if (this.props.cities.length === 0) {
            return <div></div>;
        }

        return (
            <div>
                <StatsBody title="Revenue"
                    dataTableFields={this.getDataTableFields()}
                    agg={this.props.agg}
                    type={this.props.type}
                    drillto={this.props.drillto}
                    cities={this.props.cities}
                    selectedCities={this.props.selectedCities}>
                </StatsBody>
            </div>
        )
    }
}

