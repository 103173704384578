
export function getValueForVariable(d, id, i, total, year, selectedCities, agg) {
    if (selectedCities === undefined) {
        return 1;
    }
    let population = selectedCities[i].population;
    let households = selectedCities[i].households;
    if (d[year][id] === undefined) {
        d[year][id] = { amount: 0 }
    }
    if (agg === "percentage" && total !== 0) {
        return d[year][id].amount / total;
    }
    else if (agg === "capita") {
        return d[year][id].amount / population;
    }
    else if (agg === "household") {
        return d[year][id].amount / households;
    }
    else {
        return d[year][id].amount;
    }
}

let keyedMuniData = undefined;

export function getAggregateValue(muniData, muni_id, value, agg){
    if(muniData === undefined){
        return value;
    }
    if(keyedMuniData === undefined){
        keyedMuniData = {};
        for(let muni of muniData){
            keyedMuniData[muni.id] = muni;
        }
    }

    if(agg === "capita"){
        return value / keyedMuniData[muni_id].population;
    }
    else if(agg === "household"){
        return value / keyedMuniData[muni_id].households;
    }
    else{
        return value;
    }
    


}


export function getCityNamesSlashDelimited(cityList){
    if(cityList === undefined){
        return "";
    }
    let retStr = "";
    for(let city of cityList){
        retStr += "/" + city.id;
    }
    return retStr;
}


export function getDrill(def, props) {

    let col = "C01.01";
    if (def.col !== undefined) {
        col = def.col;
    }

    function pos(x) {
        if (x < 0) {
            return 0;
        }
        else {
            return x;
        }
    }

    let lineList = [];
    let lineDesc = []
    let subDrills = undefined;
    if (def.subdrills !== undefined) {
        subDrills = def.subdrills.map(subDrill => {
            if (subDrill.custom !== undefined) {
                lineDesc.push("custom");
                lineList.push((d, i, year) => {
                    return pos(subDrill.custom.calc(d, i, 0, year));
                });
            }
            else {
                lineDesc.push("slc." + subDrill.schedule + ".L" + subDrill.linenum + "." + subDrill.col);
                lineList.push((d, i, year) => {
                    return pos(getValueForVariable(d, "slc." + subDrill.schedule + ".L" + subDrill.linenum + "." + subDrill.col, i, 0, year, props.selectedCities, "total"));
                });
            }

            return getDrill(subDrill, props)
        })
    }

    // Get rid of the total
    lineList.pop();

    let drilldown = undefined;

    if (subDrills !== undefined) {
        
        drilldown = {
            fields: subDrills,
            header: def.desc,
            getTotal: (d, i, year) => {
                let total = 0;
                for (let j = 0; j < lineList.length; j++) {
                    let curTot = lineList[j](d, i, year);
                    total += curTot;
                }

                return (total);
            }
        }
    }

    let calc = (d, i, total, year) => {
        let comp = getValueForVariable(d, "slc." + def.schedule + ".L" + def.linenum + "." + col, i, total, year, props.selectedCities, props.agg);
        return comp;
    };
    if (def.customCalc !== undefined) {
        calc = def.customCalc;
    }

    if (def.custom !== undefined) {
        return { drilldown: drilldown, ...def.custom };
    }

    return {
        id: "slc." + def.schedule + ".L" + def.linenum + "." + col,
        desc: def.desc,
        calc: calc,
        drilldown: drilldown
    }
}

export function getProtectionServicesDrilldown(column, schedule, props){
    return {
        desc: "Protection Services", linenum: "0499", schedule: schedule, col: column,
        subdrills: [
            { desc: "Fire", linenum: "0410", schedule: schedule, col: column },
            { desc: "Police", linenum: "0420", schedule: schedule, col: column },
            { desc: "Court Security", linenum: "0421", schedule: schedule, col: column },
            { desc: "Prisoner Transport", linenum: "0422", schedule: schedule, col: column },
            { desc: "Conservation Authority", linenum: "0430", schedule: schedule, col: column },
            { desc: "Emergency Measures", linenum: "0450", schedule: schedule, col: column },
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc." + schedule + ".L0460." + column + " + slc." + schedule + ".L0498." + column + " + slc." + schedule + ".L0445." + column + " + slc." + schedule + ".L0440." + column + "" },
                    desc: "POA, Permits, and Other",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.' + schedule + '.L0460.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0498.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0445.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0440.' + column, i, total, year, props.selectedCities, props.agg)
                        )
                    }
                },
                desc: "POA, Permits, and Other",
                subdrills: [
                    { desc: "Protective Inspection and Control", linenum: "0440", schedule: schedule, col: column },
                    { desc: "Building Premit and Inspection Services", linenum: "0445", schedule: schedule, col: column },
                    { desc: "Provincial Offences Act (POA)", linenum: "0460", schedule: schedule, col: column },
                    { desc: "Other", linenum: "0498", schedule: schedule, col: column },
                ]
            },
            { desc: "Total Protection Services", linenum: "0499", schedule: schedule, col: column },
        ]
    };

}

export function getTransportationServicesDrilldown(column, schedule, props){
    return {
        desc: "Transportation Services", linenum: "0699", schedule: schedule, col: column,
        subdrills: [
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc." + schedule + ".L0611." + column + " + slc." + schedule + ".L0612." + column + " + slc." + schedule + ".L0613." + column + " + slc." + schedule + ".L0614." + column + "" },
                    desc: "Roads, Bridges, and Culverts",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.' + schedule + '.L0611.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0612.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0613.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0614.' + column, i, total, year, props.selectedCities, props.agg)
                        )
                    }
                },
                desc: "Roads, Bridges, and Culverts",
                subdrills: [
                    { desc: "Roads - Paved", linenum: "0611", schedule: schedule, col: column },
                    { desc: "Roads - Unpaved", linenum: "0612", schedule: schedule, col: column },
                    { desc: "Bridges and Culverts", linenum: "0613", schedule: schedule, col: column },
                    { desc: "Traffic Operations and Roadside", linenum: "0614", schedule: schedule, col: column },
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc." + schedule + ".L0611." + column + " + slc." + schedule + ".L0612." + column + " + slc." + schedule + ".L0613." + column + " + slc." + schedule + ".L0614." + column + "" },
                            desc: "Total Roads, Bridges, and Culverts",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.' + schedule + '.L0611.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0612.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0613.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0614.' + column, i, total, year, props.selectedCities, props.agg)
                                )
                            }
                        }
                    },
                ]
            },
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc." + schedule + ".L0621." + column + " + slc." + schedule + ".L0622." + column + "" },
                    desc: "Winter Control",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.' + schedule + '.L0622.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0621.' + column, i, total, year, props.selectedCities, props.agg)
                        )
                    }
                },
                desc: "Winter Control",
                subdrills: [
                    { desc: "Winter Control - Sidewalks and Parking Lots", linenum: "0622", schedule: schedule, col: column },
                    { desc: "Winter Control - All Others", linenum: "0621", schedule: schedule, col: column },
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc." + schedule + ".L0621." + column + " + slc." + schedule + ".L0622." + column + "" },
                            desc: "Total Winter Control",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.' + schedule + 'L0622.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0621.' + column, i, total, year, props.selectedCities, props.agg)
                                )
                            }
                        }
                    },
                ]
            },
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc." + schedule + ".L0631." + column + " + slc." + schedule + ".L0632." + column + "" },
                    desc: "Transit",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.' + schedule + '.L0631.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0632.' + column, i, total, year, props.selectedCities, props.agg)
                        )
                    }
                },
                desc: "Transit",
                subdrills: [
                    { desc: "Transit - Conventional", linenum: "0631", schedule: schedule, col: column },
                    { desc: "Transit - Disabled and Special Needs", linenum: "0632", schedule: schedule, col: column },
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc." + schedule + ".L0631." + column + " + slc." + schedule + ".L0632." + column + "" },
                            desc: "Total Transit",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.' + schedule + '.L0631.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0632.' + column, i, total, year, props.selectedCities, props.agg)
                                )
                            }
                        }
                    },
                ]
            },
            { desc: "Parking", linenum: "0640", schedule: schedule, col: column },
            { desc: "Street Lighting", linenum: "0650", schedule: schedule, col: column },
            { desc: "Air Transportation", linenum: "0660", schedule: schedule, col: column },
            { desc: "Other", linenum: "0698", schedule: schedule, col: column },
            { desc: "Total Transporation Services", linenum: "0699", schedule: schedule, col: column }
        ]
    }
}

export function getEnvironmentServicesDrilldown(column, schedule, props){
    return {
        desc: "Environmental Services", linenum: "0899", schedule: schedule, col: column,
        subdrills: [
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc." + schedule + ".L0811." + column + " + slc." + schedule + ".L0812." + column + "" },
                    desc: "Wastewater",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.' + schedule + '.L0811.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0812.' + column, i, total, year, props.selectedCities, props.agg)
                        )
                    }
                },
                desc: "Wastewater",
                subdrills: [
                    { desc: "Wastewater Collection", linenum: "0811", schedule: schedule, col: column },
                    { desc: "Wastewater Treatment", linenum: "0812", schedule: schedule, col: column },
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc." + schedule + ".L0811." + column + " + slc." + schedule + ".L0812." + column + "" },
                            desc: "Total Wastewater",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.' + schedule + '.L0811.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0812.' + column, i, total, year, props.selectedCities, props.agg)
                                )
                            }
                        }
                    }
                ]
            },
            {
                custom: {
                    id: { value: "See Calculation", popover: "slc." + schedule + ".L0821." + column + " + slc." + schedule + ".L0822." + column + "" },
                    desc: "Storm Sewers",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.' + schedule + '.L0821.' + column, i, total, year, props.selectedCities, props.agg) +
                            getValueForVariable(d, 'slc.' + schedule + '.L0822.' + column, i, total, year, props.selectedCities, props.agg)
                        )
                    }
                },
                desc: "Storm Sewers",
                subdrills: [
                    { desc: "Urban storm sewer system", linenum: "0821", schedule: schedule, col: column },
                    { desc: "Rural storm sewer system", linenum: "0822", schedule: schedule, col: column },
                    {
                        custom: {
                            id: { value: "See Calculation", popover: "slc." + schedule + ".L0821." + column + " + slc." + schedule + ".L0822." + column + "" },
                            desc: "Total Storm Sewers",
                            calc: (d, i, total, year) => {
                                return (
                                    getValueForVariable(d, 'slc.' + schedule + '.L0821.' + column, i, total, year, props.selectedCities, props.agg) +
                                    getValueForVariable(d, 'slc.' + schedule + '.L0822.' + column, i, total, year, props.selectedCities, props.agg)
                                )
                            }
                        },
                    }
                ]
            },
            { desc: "Water treatment", linenum: "0831", schedule: schedule, col: column },
            { desc: "Water distribution & transmission", linenum: "0832", schedule: schedule, col: column },
            { desc: "Solid waste collection", linenum: "0840", schedule: schedule, col: column },
            { desc: "Solid waste disposal", linenum: "0850", schedule: schedule, col: column },
            { desc: "Solid waste diversion", linenum: "0860", schedule: schedule, col: column },
            { desc: "Other", linenum: "0898", schedule: schedule, col: column },
            { desc: "Total Environmental Services", linenum: "0899", schedule: schedule, col: column }

        ]

    }

}

export function getRecreationDrillDown(column, schedule, props){
    return {
        desc: "Recreation and Culture", linenum: "1699", schedule: schedule, col: column,
        subdrills: [
            { desc: "Parks", linenum: "1610", schedule: schedule, col: column },
            { desc: "Recreation Programs", linenum: "1620", schedule: schedule, col: column },
            { desc: "Facilities - Golf Courses, Marinas, Ski Hills", linenum: "1631", schedule: schedule, col: column },
            { desc: "Facilities - All Others", linenum: "1634", schedule: schedule, col: column },
            { desc: "Libraries", linenum: "1640", schedule: schedule, col: column },
            { desc: "Museums", linenum: "1645", schedule: schedule, col: column },
            { desc: "Cultural Services", linenum: "1650", schedule: schedule, col: column },
            { desc: "Other", linenum: "1698", schedule: schedule, col: column },
            { desc: "Total Recreation and Culture", linenum: "1699", schedule: schedule, col: column }
        ]
    };
}
