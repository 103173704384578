import React, { Component } from 'react';
import { StatsBody } from './StatsBody';
import { getValueForVariable } from "./Utils";


export class ExpensePage extends Component {

    getSubDrill(id, header) {
        return {
            fields: [
                {
                    id: "slc.40X." + id + ".C01.01", desc: "Salaries/Wages/ Benefits",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.01", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: "slc.40X." + id + ".C01.02", desc: "Interest on Long Term Debt",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.02", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: "slc.40X." + id + ".C01.03", desc: "Materials",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.03", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: "slc.40X." + id + ".C01.04", desc: "Contracted Services",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.04", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: "slc.40X." + id + ".C01.05", desc: "Rents and Financial Expenses",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.05", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: "slc.40X." + id + ".C01.06", desc: "External Transfers",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.06", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: "slc.40X." + id + ".C01.16", desc: "Amortization",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.16", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }, {
                    id: { "value": "See Calculation", "popover": "slc.40X." + id + ".C01.12 + slc.40X." + id + ".C01.13" },
                    desc: "Adjustments",
                    calc: (d, i, total, year) => {
                        return (
                            (
                                getValueForVariable(d, "slc.40X." + id + ".C01.12", i, total, year, this.props.selectedCities, this.props.agg) +
                                getValueForVariable(d, "slc.40X." + id + ".C01.13", i, total, year, this.props.selectedCities, this.props.agg)
                            )
                        )
                    }
                }, {
                    id: "slc.40X." + id + ".C01.11", desc: "Total After Adjustments",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, "slc.40X." + id + ".C01.11", i, total, year, this.props.selectedCities, this.props.agg)
                    }
                }
            ],
            header: header,
            getTotal: (d, i, year) => {
                function pos(x) {
                    if (x < 0) {
                        return 0;
                    }
                    else {
                        return x;
                    }
                }
                let total = pos(getValueForVariable(d, "slc.40X." + id + ".C01.12", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.13", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.16", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.06", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.05", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.04", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.03", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.02", i, 0, year, this.props.selectedCities, "total"));
                total += pos(getValueForVariable(d, "slc.40X." + id + ".C01.01", i, 0, year, this.props.selectedCities, "total"));

                return (total);
            }
        }
    }

    getDrill(linenum, desc) {
        return {
            id: "slc.40X.L" + linenum + ".C01.11", desc: desc,
            calc: (d, i, total, year) => {
                return getValueForVariable(d, "slc.40X.L" + linenum + ".C01.11", i, total, year, this.props.selectedCities, this.props.agg)
            },
            drilldown: this.getSubDrill("L" + linenum, desc)
        }
    }

    getSubGroupDrill(lineList, desc) {
        let calcDesc = lineList[0].id;
        for (let i = 1; i < lineList.length; i++) {
            calcDesc += " + " + lineList[i].id;
        }

        let calcFunc = (d, i, total, year) => {
            let val = 0;
            for (let line of lineList) {
                val += getValueForVariable(d, line.id, i, total, year, this.props.selectedCities, this.props.agg);
            }
            return val;
        }

        let drills = [];
        for (let line of lineList) {
            drills.push(this.getDrill(line.num, line.desc));
        }

        drills.push({
            id: { "value": "See Calculation", "popover": calcDesc },
            desc: "Total " + desc,
            calc: calcFunc
        });

        return {
            id: { "value": "See Calculation", "popover": calcDesc },
            desc: desc,
            calc: calcFunc,
            drilldown: {
                fields: drills,
                header: desc,
                getTotal: (d, i, year) => {
                    function pos(x) {
                        if (x < 0) {
                            return 0;
                        }
                        else {
                            return x;
                        }
                    }

                    let total = 0;
                    for (let line of lineList) {
                        total += pos(getValueForVariable(d, line.id, i, 0, year, this.props.selectedCities, "total"));
                    }

                    return (total);
                }
            }
        }
    }

    getDataTableFields() {
        return {
            fields: [
                {
                    id: "slc.40X.L0299.C01.11", desc: "Gen. Government",
                    calc: (d, i, total, year) => {
                        return getValueForVariable(d, 'slc.40X.L0299.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                    },
                    drilldown: {
                        fields: [
                            this.getDrill("0240", "Governance"),
                            this.getDrill("0250", "Corporate Management"),
                            this.getDrill("0260", "Program Support"),
                            {
                                id: "slc.40X.L0299.C01.11", desc: "Total Gen. Government Expenses",
                                calc: (d, i, total, year) => {
                                    return getValueForVariable(d, "slc.40X.L0299.C01.11", i, total, year, this.props.selectedCities, this.props.agg)
                                }
                            }
                        ],
                        totalID: "slc.40X.L0299.C01.11",
                        header: 'General Government'
                    }
                },
                {
                    id: "slc.40X.L0499.C01.11", desc: "Protection of Persons/Property",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L0499.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getDrill("0410", "Fire"),
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0420.C01.11", num: "0420", desc: "Police" },
                                { id: "slc.40X.L0421.C01.11", num: "0421", desc: "Court Security" },
                                { id: "slc.40X.L0422.C01.11", num: "0422", desc: "Prisoner Transportation" }
                            ], "Police, Court Security, and Prisoner Transport"),
                            this.getDrill("0430", "Conservation Authority"),
                            this.getDrill("0440", "Protective Inspection/Control"),
                            this.getDrill("0445", "Building Permit and Inspection Services"),
                            this.getDrill("0450", "Emergency Measures"),
                            this.getDrill("0460", "Provincial Offences Act"),
                            this.getDrill("0498", "Other"),
                            this.getDrill("0499", "Total Protection Services")
                        ],
                        totalID: "slc.40X.L0499.C01.11",
                        header: 'Protection to Persons/Property'
                    }
                },
                {
                    id: "slc.40X.L0699.C01.11", desc: "Transportation",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L0699.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0611.C01.11", num: "0611", desc: "Paved" },
                                { id: "slc.40X.L0612.C01.11", num: "0612", desc: "Unpaved" },
                                { id: "slc.40X.L0613.C01.11", num: "0613", desc: "Bridges and Culverts" },
                                { id: "slc.40X.L0614.C01.11", num: "0614", desc: "Traffic Operations and Roadside" }
                            ], "Roads"),
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0622.C01.11", num: "0622", desc: "Sidewalks and Parking" },
                                { id: "slc.40X.L0621.C01.11", num: "0621", desc: "All Others" }
                            ], "Winter Control"),
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0631.C01.11", num: "0631", desc: "Conventional" },
                                { id: "slc.40X.L0632.C01.11", num: "0632", desc: "Disabled and Special Needs" }
                            ], "Transit"),
                            this.getDrill("0640", "Parking"),
                            this.getDrill("0650", "Street Lighting"),
                            this.getDrill("0660", "Air Transportation"),
                            this.getDrill("0698", "Other"),
                            this.getDrill("0699", "Total Transportation")
                        ],
                        totalID: "slc.40X.L0699.C01.11",
                        header: 'Transportation'
                    }
                },
                {
                    id: "slc.40X.L0899.C01.11", desc: "Environment",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L0899.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0811.C01.11", num: "0811", desc: "Collection and Conveyance" },
                                { id: "slc.40X.L0812.C01.11", num: "0812", desc: "Treatment and Disposal" }
                            ], "Wastewater Expenses"),
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0821.C01.11", num: "0821", desc: "Urban Storm Sewer System" },
                                { id: "slc.40X.L0822.C01.11", num: "0822", desc: "Rural Storm Sewer System" }
                            ], "Storm Sewers"),
                            this.getDrill("0831", "Water Treatment"),
                            this.getDrill("0832", "Water distribution"),
                            this.getSubGroupDrill([
                                { id: "slc.40X.L0840.C01.11", num: "0840", desc: "Solid Waste Collection" },
                                { id: "slc.40X.L0850.C01.11", num: "0850", desc: "Solid Waste Disposal" },
                                { id: "slc.40X.L0860.C01.11", num: "0860", desc: "Waste Diversion" }
                            ], "Solid Waste"),
                            this.getDrill("0898", "Other"),
                            this.getDrill("0899", "Total Environmental Expenses")
                        ],
                        totalID: "slc.40X.L0899.C01.11",
                        header: 'Environment'
                    }
                },
                {
                    id: "slc.40X.L1099.C01.11", desc: "Health and Emergency",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L1099.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getDrill("1010", "Public Health Services"),
                            this.getDrill("1020", "Hospitals"),
                            this.getDrill("1030", "Ambulance Services"),
                            this.getDrill("1035", "Ambulance Dispatch"),
                            this.getDrill("1040", "Cemeteries"),
                            this.getDrill("1098", "Other"),
                            this.getDrill("1099", "Total Health Services Expenses")
                        ],
                        totalID: "slc.40X.L1099.C01.11",
                        header: 'Health Services'
                    }
                },
                {
                    id: { "value": "See Calculation", "popover": "slc.40X.L1499.C01.11 + slc.40X.L1299.C01.11" }, desc: "Social, Family and Social Housing",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L1299.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                            getValueForVariable(d, 'slc.40X.L1499.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getDrill("1210", "General Assistance"),
                            this.getDrill("1220", "Assistance to Aged Persons"),
                            this.getDrill("1230", "Child Care"),
                            this.getDrill("1410", "Public Housing"),
                            this.getDrill("1420", "Non-Profit/ Coop Housing"),
                            this.getDrill("1430", "Rent Supplement Programs"),
                            this.getSubGroupDrill([
                                { id: "slc.40X.L1298.C01.11", num: "1298", desc: "Social/Family Other" },
                                { id: "slc.40X.L1497.C01.11", num: "1497", desc: "Social Housing Other 1" },
                                { id: "slc.40X.L1498.C01.11", num: "1498", desc: "Social Housing Other 2" }
                            ], "Others"),
                            {
                                id: { "value": "See Calculation", "popover": "slc.40X.L1499.C01.11 + slc.40X.L1299.C01.11" }, desc: "Social, Family and Social Housing Total",
                                calc: (d, i, total, year) => {
                                    return (
                                        getValueForVariable(d, 'slc.40X.L1299.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.40X.L1499.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                                    )
                                }
                            }
                        ],
                        getTotal: (d, i, year) => {
                            return (
                                getValueForVariable(d, 'slc.40X.L1299.C01.11', i, 0, year, this.props.selectedCities, "total") +
                                getValueForVariable(d, 'slc.40X.L1499.C01.11', i, 0, year, this.props.selectedCities, "total")
                            )
                        },
                        header: 'Social and Housing Expenses'
                    }
                },
                {
                    id: "slc.40X.L1699.C01.11", desc: "Recreation and Cultural",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L1699.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getDrill("1610", "Parks"),
                            this.getDrill("1620", "Recreation Programs"),
                            this.getDrill("1631", "Golf Courses, Marinas & Ski Hills"),
                            this.getDrill("1634", "Facilities - All Others"),
                            this.getDrill("1640", "Libraries"),
                            this.getDrill("1645", "Museums"),
                            this.getDrill("1650", "Cultural Services"),
                            this.getDrill("1698", "Other"),
                            this.getDrill("1699", "Total Recreation and Cultural Services")
                        ],
                        totalID: "slc.40X.L1699.C01.11",
                        header: 'Recreation and Cultural Services'
                    }
                },
                {
                    id: { "value": "See Calculation", "popover": "slc.40X.L1899.C01.11 + slc.40X.L1910.C01.11" }, desc: "Planning, Devel. and Other",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L1899.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                            + getValueForVariable(d, 'slc.40X.L1910.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    },
                    drilldown: {
                        fields: [
                            this.getDrill("1810", "Planning and Zoning"),
                            this.getDrill("1820", "Commercial and Industrial"),
                            this.getDrill("1830", "Residential Development"),
                            this.getDrill("1840", "Agriculture and Reforestation"),
                            this.getDrill("1850", "Tile Drainage and Shoreline"),
                            this.getDrill("1898", "Planning and Development - Other"),
                            this.getDrill("1910", "Other Expenses - General"),
                            {
                                id: { "value": "See Calculation", "popover": "slc.40X.L1899.C01.11 + slc.40X.L1910.C01.11" }, desc: "Social, Family and Social Housing Total",
                                calc: (d, i, total, year) => {
                                    return (
                                        getValueForVariable(d, 'slc.40X.L1899.C01.11', i, total, year, this.props.selectedCities, this.props.agg) +
                                        getValueForVariable(d, 'slc.40X.L1910.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                                    )
                                }
                            }
                        ],
                        getTotal: (d, i, year) => {
                            return (
                                getValueForVariable(d, 'slc.40X.L1899.C01.11', i, 0, year, this.props.selectedCities, "total") +
                                getValueForVariable(d, 'slc.40X.L1910.C01.11', i, 0, year, this.props.selectedCities, "total")
                            )
                        },
                        header: 'Planning and Development'
                    }
                },
                {
                    id: "slc.40X.L9910.C01.11", desc: "Total",
                    calc: (d, i, total, year) => {
                        return (
                            getValueForVariable(d, 'slc.40X.L9910.C01.11', i, total, year, this.props.selectedCities, this.props.agg)
                        )
                    }
                }
            ],
            totalID: 'slc.40X.L9910.C01.11',
            header: 'All Expenses'
        };
    }



    render() {

        return (
            <div>
                <StatsBody title="Expenses"
                    dataTableFields={this.getDataTableFields()}
                    agg={this.props.agg}
                    type={this.props.type}
                    drillto={this.props.drillto}
                    cities={this.props.cities}
                    selectedCities={this.props.selectedCities}
                >
                </StatsBody>
            </div>
        )
    }
}

