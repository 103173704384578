import React, { Component } from 'react';
import { Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import { LinearBubbleChart } from './LinearBubbleChart';
import { StackedBarChart } from './StackedBarChart';
import { DataTable } from './DataTable';
import { Redirect } from 'react-router-dom';
import { getCityNamesSlashDelimited } from './Utils';

const currYear = '2018';

export class StatsBody extends Component {

    constructor(props){
        super(props);
        this.state = {adjust2009: false};
        this.redirected = false;
    }

    colours = [
        "blues",
        "yellows",
        "greens",
        "reds",
        "purples",
        "greys"
    ];

    getTotal(d, i, year, dataFields) {
        if(dataFields.totalID === undefined && dataFields.getTotal !== undefined){
            return dataFields.getTotal(d, i, year);
        }
        if(d[year][dataFields.totalID] === undefined){
            let sum = 0;
            for(let key in d[year]){
                if(d[year][key].amount > 0){
                    sum += d[year][key].amount;
                }
            }
            return sum;
        }
        return d[year][dataFields.totalID].amount;
    }

    
    getRevStreams(year, dataFields) {
        return this.props.cities.map((d, i) => {
            if (d[year] === undefined) {
                return { revenue_streams: [] }
            }

            let total = this.getTotal(d, i, year, dataFields);

            let streams = dataFields.fields.slice(0, -1).map(field => {
                let val = Object.assign({}, field);
                val.value = field.calc(d, i, total, year);
                return val;
            })

            return {
                city: d.desc,
                revenue_streams: streams
            }

        });
    }

    renderBubbleGraphs(dataFields) {
        let cityData = this.getRevStreams(currYear, dataFields);

        let maxValue = 0;
        if (cityData.length > 0) {
            maxValue = cityData.reduce((acc, curr) => {
                if (curr.revenue_streams.length > 0) {
                    let currMax = curr.revenue_streams.reduce((currAcc, currCurr) => {
                        if (Math.abs(currCurr.value) > currAcc) {
                            return Math.abs(currCurr.value);
                        }
                        else {
                            return currAcc;
                        }
                    }, 0);
                    if (currMax > acc) {
                        return currMax;
                    }
                    else {
                        return acc;
                    }
                }
                else {
                    return 0;
                }
            }, 0)
        }

        return cityData.map((data, i) => {
            let offset = 0;
            if (i === 2) {
                offset = 3;
            }
            if (cityData.length === 1) {
                offset = 3;
            }

            let desc = "Totals";

            if (this.props.agg === "percentage") {
                desc = "Proportions"
            }
            else if (this.props.agg === "capita") {
                desc = "Per Capita"
            }
            else if (this.props.agg === "household") {
                desc = "Per Household"
            }

            return (
                
            <Col key={i} xs={12} xl={{ span: 6, offset: offset }}>
                <h6 className="text-center" >{currYear} {this.props.title} - {desc} - {data.city}</h6>
                <LinearBubbleChart bubbleClick={this.bubbleClick(this.props.drillto)} maxValue={maxValue} type={this.props.agg} colours={this.colours[i]} data={data.revenue_streams}></LinearBubbleChart>
                <br /><br /><br />
            </Col>)
        })
    }

    renderDataTable(dataFields) {
        let year = currYear;

        let dataArr = this.props.cities.map((d, i) => {
            if (d[year] === undefined) {
                return []
            }

            let total = this.getTotal(d, i, year, dataFields);
            let annotation = "";

            return dataFields.fields.map(field => {
                if(field.id.substring !== undefined){
                    let annoID = field.id.substring(0, field.id.length - 1) + "A";
                    let ano = d[year][annoID];
                    if(ano !== undefined){
                        annotation = ano.value_text;
                    }
                    else{
                        annotation = "";
                    }
                }
                else{
                    annotation = "";
                }

                return {value: field.calc(d, i, total, year), annotation: annotation};
            })

        });

        if(dataArr.length === 0){
            return;
        }

        return (
            <Col>
                <DataTable linkClick={this.bubbleClick(this.props.drillto)} agg={this.props.agg} type={this.props.type} drillto={this.props.drillto} data={dataArr} 
                    fields={dataFields.fields} cities={this.props.cities}></DataTable>
                <br />
            </Col>
        )

    }

    getCityBarData(dataFields, adjust) {

        const years = ["2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019"];

        let adjustment = {
            "2009" : 1,
            "2010" : 1,
            "2011" : 1,
            "2012" : 1,
            "2013" : 1,
            "2014" : 1,
            "2015" : 1,
            "2016" : 1,
            "2017" : 1,
            "2018" : 1,
            "2019" : 1,
        }

        if(this.state.adjust2009){
            adjustment = {
                "2009" : 1,
                "2010" : 1.01835664335664,
                "2011" : 1.04807692307692,
                "2012" : 1.06381118881119,
                "2013" : 1.07342657342657,
                "2014" : 1.09440559440559,
                "2015" : 1.10664335664336,
                "2016" : 1.12237762237762,
                "2017" : 1.13986013986014,
                "2018" : 1.16608391608392,
                "2019" : 1.18881118881119,
            }
        }


        let cityBarData = this.props.cities.map((d, i) => {
            let cityObject = { city: d.desc };
            cityObject.year_data = years.map((year) => {

                let retObject = {
                    year: year,
                }
                if (d[year] === undefined) {
                    for(let field of dataFields.fields){
                        retObject[field.desc] = 0;
                    }
                    return retObject;
                }

                let totalUp = 0;
                let total = this.getTotal(d, i, year, dataFields);

                for(let field of dataFields.fields.slice(0,-1)){
                    let annotation = "";
                    if(field.id.substring !== undefined){
                        let annoID = field.id.substring(0, field.id.length - 1) + "A";
                        let ano = d[year][annoID];
                        if(ano !== undefined){
                            annotation = ano.value_text;
                        }
                        else{
                            annotation = "";
                        }
                    }
                    else{
                        annotation = "";
                    }

                    retObject[field.desc] = {
                        value: field.calc(d, i, total, year) / adjustment[year],
                        annotation: annotation                       
                    };
                    if(retObject[field.desc].value > 0){
                        totalUp += retObject[field.desc].value;
                    }
                }
                retObject.total = totalUp  / adjustment[year];

                return retObject;
            })

            return cityObject;
        })

        return cityBarData;        
    }


    renderStackedBarChart(dataFields) {
        const years = ["2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019"];


        let desc = "Totals";

        if (this.props.agg === "percentage") {
            desc = "Proportions"
        }
        else if (this.props.agg === "capita") {
            desc = "Per Capita"
        }
        else if (this.props.agg === "household") {
            desc = "Per Household"
        }
        
        let barData = this.getCityBarData(dataFields);
        if(barData === undefined){
            return;
        }

        
        let fieldNames = dataFields.fields.slice(0,-1).map(d => d.desc);

        return (
            <Col>
                <h6 className="text-center" >Yearly {this.props.title} - {desc}
                </h6>
                <span className="text-center">                    
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check value={this.state.adjust2009} onChange={ d => {
                            console.log(this.state.adjust2009);
                            this.setState({adjust2009: !this.state.adjust2009})
                        }} type="checkbox" label={"Values in 2009 dollars: " + this.getOnOff(this.state.adjust2009)}/>
                    </Form.Group>
                </span>
                <StackedBarChart group="city" keys={fieldNames} data={barData} years={years} type={this.props.agg}></StackedBarChart>
                
                <br />
                <br />
                <br />
                <br />
            </Col>
        )
    }

    getOnOff(d){
        if(d){
            return "ON"
        }
        else{
            return "OFF"
        }
    }

    getDrillURL(drillto){
        return "/" + this.props.type + "/" + this.props.agg + drillto + getCityNamesSlashDelimited(this.props.cities);

    }

    bubbleClick(currentDrill){
        let priorDrill = "";
        if(currentDrill !== undefined){
            priorDrill = currentDrill + "-";
        }
        return (drillto) => {
            this.redirected = false;
            this.setState({
                redirectTo: this.getDrillURL("/drillto-" + priorDrill + drillto)
            })
        }
    }

    render() {
        if (this.props.cities.length === 0) {
            return <div></div>;
        }

        let dataFields = this.props.dataTableFields;
        let headers = [{ desc: dataFields.header, drillto: undefined}];
        if(this.props.drillto !== undefined && this.props.dataTableFields !== undefined){
            
            let drillNumbers = this.props.drillto.split("-");

            
            for(let drillNum of drillNumbers){
                dataFields = dataFields.fields[drillNum].drilldown;
                headers.push({ desc: dataFields.header, drillto: drillNum});
            }

        }

        let getDrillClosure = (drillto) => {
            return () => {
                this.redirected = false;
                this.setState({redirectTo: this.getDrillURL(drillto)})
            };
        }

        let getDrillDowns = () => {
            let drillto = "";
            
            return headers.map((header, i) => {
                let active = i === headers.length - 1;
                if(i === 1){
                    drillto = "/drillto-" + header.drillto;
                }
                else if(i >= 2){
                    drillto += "-" + header.drillto;
                }
                if(active){
                    return (
                        <Breadcrumb.Item key={header.desc} active={active}>
                            {header.desc}
                        </Breadcrumb.Item>
                    )
                }
                else {
                    return (
                        <li key={header.desc}>
                            <span className="link pointer-cursor" onClick={getDrillClosure(drillto)} >
                                {header.desc}
                            </span>
                            &nbsp;/&nbsp;
                        </li>
                    )
                }
                
            })
        }

        let getRedirect = () => {
            if(this.state.redirectTo && !this.redirected){
                this.redirected = true;
                return <Redirect to={this.state.redirectTo} push={true}></Redirect>;
            }
        }

        return (
            <div>
                {getRedirect()}
                <Row>
                    <Col>
                        <Breadcrumb>
                            {getDrillDowns()}
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    {this.renderBubbleGraphs(dataFields)}
                </Row>

                <Row>
                    {this.renderDataTable(dataFields)}
                </Row>

                <Row>
                    {this.renderStackedBarChart(dataFields)}
                </Row>
            </div>
        )
    }


}