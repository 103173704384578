import React, { Component } from 'react';
import { Tabs, Tab, DropdownButton, Dropdown, Row, Col } from 'react-bootstrap';


function WhoWhatBody(props){
    return <h1>{props.muni_id}</h1>
}


export class WhoWhatPage extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        
        this.redirected = false;
    }



    setSelected() {
        return (selected) => {
            this.redirected = false;
            this.setState({selectedCity: selected})
        }
    }

    yearSelected(year){
        return () => {
            this.redirected = false;
            this.setState({currYear: year});
        }
    }

    renderDataTable() {
        return "datatable";

    }


    render() {
        
        let year = "2018";

        if(this.props.cities === undefined || this.props.cities.length === 0){
            return "";
        }
        let selected = this.props.cities[0].id;

        
        if(this.state.selectedCity !== undefined){
            selected = this.state.selectedCity;
        }
        else if(this.props.selectedCity !== undefined){
            selected = this.props.selectedCity;
        }

        if(this.state.currYear !== undefined){
            year = this.state.currYear;
        }
        else if(this.props.year === "undefined"){
            year = "2018";
        }
        else if(this.props.year !== undefined){
            year = this.props.year;
        }


        let TabList = this.props.cities.map((city) => {
            return (
            <Tab key={city.id} eventKey={city.id} title={city.desc}>
                <br/>
                <Row>
                    <Col md="auto">
                         <WhoWhatBody agg={this.props.agg} muni_id={city.id} muniData={this.props.muniData} data={city[year]}></WhoWhatBody>
                    </Col>
                </Row>
            </Tab>
            )
        });



        return (
            <div>
                <Row>
                    <Col>
                        <div role="toolbar" className="btn-toolbar">
                            &nbsp;&nbsp;&nbsp;
                            <DropdownButton className="pad-right" id="dropdown-item-button" variant="dark" title={year}>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2009')}>2009</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2010')}>2010</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2011')}>2011</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2012')}>2012</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2013')}>2013</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2014')}>2014</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2015')}>2015</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2016')}>2016</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2017')}>2017</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2018')}>2018</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.yearSelected('2019')}>2019</Dropdown.Item>
                            </DropdownButton>
                            <br/><br/>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Tabs id="citydetailtab" activeKey={selected} onSelect={this.setSelected()}>
                            {TabList}
                        </Tabs>
                    </Col>
                    
                    <Col></Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderDataTable()}
                    </Col>
                </Row>
            </div>
        )
    }

}
